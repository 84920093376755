@import './background_image/background_image.scss';
@import './buttons/buttons.scss';
@import './scrollbar/scrollbar.scss';

/* Utitlity classes */
.red {
  color: color('error') !important;
}

.error {
  border-color: color('error') !important;
}

.text-muted {
  font-size: 1.2rem !important;
  color: color('footer', 'text', 'accent') !important;
  @include opacity(0.35);
}

html {
  body {
    .additional-links {
      display: inline;

      i {
        position: relative;
        width: auto;
        top: 0;
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }
}